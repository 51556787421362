import * as React from 'react';
import Helmet from 'react-helmet';
import Layout from '../layouts/Layout';
import BottomHero from '../components/shared/BottomHero';
import FancyPageHeader from '../components/shared/FancyPageHeader';
import SvgIcon from '../components/SvgIcon';
import SinglePoint from '../components/hosting/SinglePoint';
import Link from '../components/Link';
import DividerWithCTA from '../components/hosting/DividerWithCTA';
import Image from '../components/Image';
import Testimonials from '../components/homepage/Testimonials';
import FullDetailsTable, { HostingSpecs } from '../components/pricing/FullDetailsTable';
import Plan from '../components/pricing/Plan';

// The design / layout is inspired by some blocks in https://preview.webpixels.io/boomerang-v3.6.1/pages/homepages/homepage-2.html
// and https://preview.webpixels.io/boomerang-v3.6.1/pages/homepages/homepage-3.html.

interface Props {
  data: {
    specs: HostingSpecs;
  };
}

const HostingPage: React.StatelessComponent<Props> = ({ data: { specs } }) => (
  <>
    <Helmet>
      <title>WordPress hosting built on Google Cloud</title>
    </Helmet>
    <Layout className='pricing'>
      <FancyPageHeader
        title='Docker-based hosting'
        subtitle='WordPress hosting with dedicated resources and useful developer features'
      />

      <section className='slice-lg'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='fluid-paragraph-2 text-center'>
                <p className='lead line-height-1_8 strong-400 mt-3'>
                  <strong>VersionPress.com hosting</strong> runs on Google Cloud and provides fast, reliable &amp;
                  developer-friendly platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-12 d-flex'>
              <figure className='figure mx-auto'>
                <Image
                  src='https://res.cloudinary.com/vpimg/image/upload/w_1079,e_sharpen:150/v1539088768/versionpress.com/hero.png'
                  className='img-fluid img-center rounded z-depth-3-top'
                  alt='Workflows on VersionPress.com hosting'
                  maxWidth={818}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />

      <section className='built-on-containers slice-lg pt-0'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2'>
              <div className='py-4'>
                <div className='text-center text-md-left'>
                  <h3 className='heading heading-2 strong-500'>Built on containers</h3>
                  <p className='text-lg line-height-1_8 mt-3'>
                    Containers combine the advantages of shared hosting and VPS's: you get dedicated resources, high
                    security and availability while keeping the cost down.
                  </p>
                </div>
                <span className='space-xs-xl' />
                <div className='row-wrapper'>
                  <div className='row'>
                    <SinglePoint
                      title='Docker'
                      icon={<SvgIcon src='https://png.icons8.com/ios/50/000000/docker.svg' width='45px' />}
                    >
                      The platform is built on Docker and Kubernetes, industry-leading technologies.
                    </SinglePoint>
                    <SinglePoint
                      title='Google Cloud'
                      icon={
                        <SvgIcon src='https://png.icons8.com/ios/50/000000/google-cloud-platform.svg' width='42px' />
                      }
                    >
                      First-class infrastructure, data centers around the world, fast network. Google Cloud has been a
                      great partner.
                    </SinglePoint>
                  </div>
                  <div className='row'>
                    <SinglePoint
                      title='Dedicated resources'
                      icon={
                        <SvgIcon src='https://png.icons8.com/ios/50/000000/google-compute-engine.svg' width='42px' />
                      }
                    >
                      Your RAM is your RAM, your CPU is your CPU.
                    </SinglePoint>
                    <SinglePoint
                      title='Speed'
                      icon={
                        <SvgIcon
                          src='https://unpkg.com/@icon/feather/icons/zap.svg'
                          width='42px'
                          className='feather-icon'
                        />
                      }
                    >
                      Containers are lightweight and fast; we always care about performance of your sites.
                    </SinglePoint>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DividerWithCTA />

      <section className='slice-lg sct-color-1'>
        <div className='container'>
          <div className='section-title section-title--style-1 text-center mb-2'>
            <h3 className='section-title-inner tt-none'>
              <span>Modern technologies</span>
            </h3>
            <span className='section-title-delimiter clearfix d-none' />
          </div>
          <span className='clearfix' />
          <div className='fluid-paragraph fluid-paragraph-sm text-center'>
            Our philosophy is to support the latest and greatest – we believe that there are enough hosts offering
            legacy technologies already.
          </div>
          <span className='space-xs-xl' />
          <div className='row cols-xs-space cols-sm-space cols-md-space'>
            <div className='col-lg-3 col-md-6'>
              <div className='block block--style-3'>
                <div className='block-body'>
                  <div className='icon-block--style-1-v5 text-center'>
                    <div className='block-icon-lg c-gray-dark mt-4' />
                    <div className='block-content mt-1'>
                      <h3 className='heading heading-5 strong-500'>PHP 7</h3>
                      <p>
                        All sites get PHP 7.{' '}
                        <Link to={`https://secure.php.net/supported-versions.php`}>Orange and red versions</Link>, incl.
                        5.6, are not supported.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='block block--style-3'>
                <div className='block-body'>
                  <div className='icon-block--style-1-v5 text-center'>
                    <div className='block-icon-lg c-gray-dark mt-4' />
                    <div className='block-content mt-1'>
                      <h3 className='heading heading-5 strong-500'>Nginx web servers</h3>
                      <p>Nginx is a faster web server than Apache, we use it for all sites.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='block block--style-3'>
                <div className='block-body'>
                  <div className='icon-block--style-1-v5 text-center'>
                    <div className='block-icon-lg c-gray-dark mt-4' />
                    <div className='block-content mt-1'>
                      <h3 className='heading heading-5 strong-500'>HTTP/2</h3>
                      <p>All sites get HTTP/2, which also means they are HTTPS, and HTTPS-only.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='block block--style-3'>
                <div className='block-body'>
                  <div className='icon-block--style-1-v5 text-center'>
                    <div className='block-icon-lg c-gray-dark mt-4' />
                    <div className='block-content mt-1'>
                      <h3 className='heading heading-5 strong-500'>WP-CLI</h3>
                      <p>
                        WP-CLI is an <em>awesome</em> way to manage WordPress sites, we support it out of the box.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DividerWithCTA />

      <section className='dev-tools slice-lg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2'>
              <div className='py-4'>
                <div className='text-center text-md-left'>
                  <h3 className='heading heading-2 strong-500'>Time-saving tools</h3>
                  <p className='text-lg line-height-1_8 mt-3'>
                    Your web platform should be more than "just hosting". We value your productivity.
                  </p>
                </div>
                <span className='space-xs-xl' />
                <div className='row-wrapper'>
                  <div className='row'>
                    <SinglePoint
                      title='Beautiful user portal'
                      icon={
                        <SvgIcon
                          src='https://unpkg.com/@icon/feather/icons/chrome.svg'
                          width='40px'
                          className='feather-icon'
                        />
                      }
                    >
                      With minimalism in mind, the <Link to={`https://versionpress.com/docs/portal/`}>portal</Link>{' '}
                      focuses on what's important. No cPanels here!
                    </SinglePoint>
                    <SinglePoint
                      title='Full SSH'
                      icon={
                        <SvgIcon
                          src='https://unpkg.com/@icon/feather/icons/hash.svg'
                          width='40px'
                          className='feather-icon'
                        />
                      }
                    >
                      All your favorite tools like WP-CLI, Git, Composer or <code>:wq</code>.
                    </SinglePoint>
                  </div>
                  <div className='row'>
                    <SinglePoint
                      title='Helpful logs'
                      icon={
                        <SvgIcon
                          src='https://unpkg.com/@icon/feather/icons/clipboard.svg'
                          width='40px'
                          className='feather-icon'
                        />
                      }
                    >
                      With a <Link to={`https://versionpress.com/docs/logs/`}>lovely web UI</Link> and full-text search,
                      it will be there when you need it.
                    </SinglePoint>
                    <SinglePoint
                      title='Web terminal'
                      icon={
                        <SvgIcon
                          src='https://unpkg.com/@icon/feather/icons/monitor.svg'
                          width='40px'
                          className='feather-icon'
                        />
                      }
                    >
                      You'll love this: get a terminal session{' '}
                      <Link to={`https://versionpress.com/docs/web-terminal/`}>right from your browser</Link>.
                    </SinglePoint>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DividerWithCTA />

      <section className='pricing mt-xl mb-xl sct-color-1 pb-6'>
        <div className='container'>
          <div className='section-title section-title--style-1 text-center mb-5'>
            <h3 className='section-title-inner tt-none'>
              <span>Pricing</span>
            </h3>
            <span className='section-title-delimiter clearfix d-none' />
          </div>
          <span className='clearfix' />

          <div className='row cols-xs-space cols-md-space cols-lg-space'>
            <Plan
              planName='Solo'
              howManySites={1}
              price={19}
              iconUrl='https://unpkg.com/@icon/linea-basic-elaboration/icons/basic-elaboration-message-happy.svg'
            />
            <Plan
              planName='Developer'
              howManySites={10}
              price={129}
              iconUrl='https://unpkg.com/@icon/linea-basic-elaboration/icons/basic-elaboration-message-plus.svg'
            />
            <Plan
              planName='Agency'
              howManySites={30}
              price={299}
              iconUrl='https://unpkg.com/@icon/linea-basic-elaboration/icons/basic-elaboration-message-heart.svg'
            />
          </div>
        </div>
      </section>

      <section className='slice-sm' id='details'>
        <div className='container'>
          <div className='section-title section-title--style-1 text-center'>
            <h3 className='section-title-inner heading-3 text-normal strong-600'>Full technical details</h3>
          </div>
          <div className='row cols-xs-space cols-md-space cols-lg-space'>
            <div className='col-lg-10 offset-lg-1 text-center'>
              <FullDetailsTable specs={specs} />
            </div>
          </div>
        </div>
      </section>

      <BottomHero>
        <div className='heading heading-inverse heading-1 strong-600 mt-3'>Customer links:</div>
        <Link to='https://my.versionpress.com' className='btn btn-styled btn-lg btn-white btn-circle mt-5'>
          Log in
        </Link>
        <Link to='/docs/' className='btn btn-styled btn-lg btn-white btn-outline btn-circle mt-5 ml-4'>
          Docs
        </Link>
        <p className='mt-5 c-white-7'>
          <Link to='/terms-of-service/' className='c-white-7 pr-4'>
            Terms of Service
          </Link>
          <Link to='/privacy-policy/' className='c-white-7'>
            Privacy Policy
          </Link>
        </p>
      </BottomHero>
    </Layout>
  </>
);

export default HostingPage;

export const query = graphql`
  {
    # There can be multiple JSONs in the 'legal' folder, fetch the one that has some plans in it
    specs: legalJson(plans: { elemMatch: { name: { glob: "*" } } }) {
      plans {
        name
      }
      features {
        feature
        description
        value
        values
      }
    }
  }
`;
