import * as React from 'react';
import Link from '../Link';

const DividerWithCTA: React.StatelessComponent = () => (
  <section className='slice-sm sct-color-2 border-top border-bottom'>
    <div className='container'>
      <div className='px-4'>
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-lg-9'>
              <div className='text-center text-lg-left'>
                <p className='lead text-normal'>With each account, you get free sites for development.</p>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='py-4 text-center text-lg-right'>
                <Link to='/get-started/' className='btn btn-styled btn-base-1 btn-circle'>
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DividerWithCTA;
