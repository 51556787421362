import * as React from 'react';

/**
 * See also docs/legal/plans.schema.json
 */
export interface HostingSpecs {
  plans: {
    name: string;
  }[];

  features: {
    feature: string;
    description: string;
    value: string;
    values: string[];
  }[];
}

interface Props {
  specs: HostingSpecs;
}

const FullDetailsTable: React.StatelessComponent<Props> = ({ specs: { plans, features } }) => (
  <div className='table-responsive'>
    <table className='full-details-table table'>
      <thead>
        <tr>
          <th scope='col' className='description-column'>
            &nbsp;
          </th>
          {plans.map(plan => (
            <th scope='col' className='plan-column' key={`plan-${plan.name}`}>
              {plan.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {features.map(feature => (
          <tr key={`feature-${feature.feature}`}>
            <td>
              <span
                className='feature-name'
                title={feature.description}
                data-toggle={feature.description ? 'tooltip' : undefined}
                data-placement={feature.description ? 'top' : undefined}
                data-delay={feature.description ? 500 : undefined}
              >
                {feature.feature}
              </span>
            </td>
            {plans.map((plan, index) => {
              const value = feature.values ? feature.values[index] : feature.value;
              return <td key={`value-${value}-${index}`}>{value}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default FullDetailsTable;
