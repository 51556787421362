import * as React from 'react';

const BottomHero: React.SFC = props => (
  <section className='sct-color-1'>
    <div
      className='wave-shape shape-container relative'
      data-shape-fill='primary'
      data-shape-style='opaque_waves_1'
      data-shape-position='top'
      style={{ height: 100 }}
    >
      <svg className='shape-item' viewBox='0 0 100 100' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 0 C 50 100 80 100 100 0 Z' />
      </svg>
    </div>
    <div className='slice-lg bg-base-1'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-8 text-center'>{props.children}</div>
        </div>
      </div>
    </div>
  </section>
);

export default BottomHero;
