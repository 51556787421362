import * as React from 'react';
import SvgIcon from '../SvgIcon';

interface Props {
  title: string;
  icon?: React.ReactNode;
}

const SinglePoint: React.StatelessComponent<Props> = props => (
  <div className='col-12 col-lg-6'>
    <div className={props.icon ? 'icon-block icon-block--style-1-v1' : 'px-4'}>
      <div className='block-icon'>{props.icon}</div>
      <div className='block-content'>
        <h3 className='heading heading-4 strong-500'>{props.title}</h3>
        <p className='mt-3'>{props.children}</p>
      </div>
    </div>
  </div>
);

export default SinglePoint;
