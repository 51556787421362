import * as React from 'react';
import SvgIcon from '../SvgIcon';
import Link from '../Link';

interface Props {
  planName: string;
  iconUrl: string;
  price: number;
  howManySites: number;
}

const Plan: React.StatelessComponent<Props> = props => (
  <div className='plan col-lg-4'>
    <div className='card z-depth-2-top z-depth-3--hover'>
      <div className='card-body text-center'>
        <div className='icon-block--style-1-v5'>
          <div className='block-icon block-icon-lg pt-4'>
            <div style={{ height: '60px' }}>
              <SvgIcon src={props.iconUrl} height='60px' />
            </div>
          </div>
          <div className='block-content'>
            <h3 className='heading heading-5 strong-600 text-uppercase'>{props.planName}</h3>
            <ul className='icons text-center mt-4'>
              <li className='c-gray-light strong-600'>
                {props.howManySites} production site{props.howManySites > 1 ? 's' : ''}
              </li>
              <li className='c-gray-light strong-400'>Unlimited dev sites</li>
              <li className='c-gray-light strong-400'>Staging with DB merging</li>
            </ul>
            <span className='price-tag price-tag--1'>
              <sup>$</sup>
              <span className='price strong-700'>{props.price}</span>
              <span className='price-type'>/mo</span>
            </span>
            <Link
              to='/get-started/'
              className='btn btn-styled btn-base-1 btn-circle btn-shadow text-uppercase strong-600 mt-3 px-5'
            >
              Get started
            </Link>
            <span className='clearfix' />
            <a href='#details' className='link link-sm link--style-3 text-capitalize mt-2'>
              Full details
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Plan;
