import * as React from 'react';
import { ReactSVG } from 'react-svg';

interface Props {
  src: string;
  width?: string;
  height?: string;
  className?: string;
}

const SvgIcon: React.StatelessComponent<Props> = ({ src, width = '100%', height = '100%', className }) => (
  <ReactSVG
    src={src}
    className={className}
    beforeInjection={(svg) => {
      svg.setAttribute('width', width);
      svg.setAttribute('height', height);
    }}
  />
);

export default SvgIcon;
