import * as React from 'react';
import Link from '../Link';

import './Testimonials.scss';

interface TestimonialProps {
  name: string;
  bio: React.ReactNode;
  avatar: React.ReactNode;
  testimonial: React.ReactNode;
}

const Testimonial: React.StatelessComponent<TestimonialProps> = props => (
  <div className='col-lg-4'>
    <div className='card card-blockquote z-depth-3-top'>
      <div className='card-body'>
        <div className='block-author'>
          <div className='author-image'>{props.avatar}</div>
          <div className='author-info'>
            <span className='d-block heading-6 author-name strong-600 c-base-1'>{props.name}</span>
            <span className='heading-xs author-desc'>{props.bio}</span>
          </div>
        </div>
        <p className='mt-4 line-height-1_8'>{props.testimonial}</p>
      </div>
    </div>
  </div>
);

const Testimonials: React.StatelessComponent = () => (
  <section className='testimonials slice-lg sct-color-1'>
    <div className='container'>
      <div className='section-title section-title--style-1 text-center'>
        <h3 className='section-title-inner heading-3 text-normal strong-600'>What people say about VersionPress.com</h3>
      </div>
      <span className='space-xs-xl' />
      <div className='row cols-xs-space cols-md-space cols-lg-space'>
        <Testimonial
          name='Jan Stetina'
          bio={
            <>
              Developer, <Link to='https://toolset.com/'>Toolset</Link>
            </>
          }
          avatar={
            <img src='https://res.cloudinary.com/vpimg/image/upload/c_crop,g_face,w_254/c_scale,w_120/v1538506922/versionpress.com/jan-stetina.jpg' />
          }
          testimonial={`Database merging is an important problem to solve, I'm a big fan of VersionPress!`}
        />
        <Testimonial
          name='Karolina Vyskocilova'
          bio={
            <>
              WordPress developer, <Link to='https://kybernaut.cz/'>Kybernaut</Link>
            </>
          }
          avatar={
            <img src='https://res.cloudinary.com/vpimg/image/upload/c_scale,w_120/v1538504739/versionpress.com/kv_portret_800x800.jpg' />
          }
          testimonial={`As a developer, I want to have websites under my control. VersionPress.com gives me live & staging environments, WP-CLI and excellent support.`}
        />
        <Testimonial
          name='Adam Laita'
          bio={
            <>
              Performance specialist, <Link to='https://www.laita.cz/en/'>laita.cz</Link>
            </>
          }
          avatar={<img src='https://www.gravatar.com/avatar/dc2fbc93252113f3383d0255be52faf8' />}
          testimonial='VersionPress.com is my preferred WordPress hosting: fast &amp; great value for money.'
        />
      </div>
    </div>
  </section>
);

export default Testimonials;
